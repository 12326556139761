import React from "react"

class BreeezEmailSignature extends React.Component {
  constructor() {
    super();
    this.state = {
      name: 'Nigel French',
      title: 'Fictional Consultant',
      phone: '0115 959 XXXX',
      social: true
    }
    this.toggleSocial = this.toggleSocial.bind(this);
  }

  toggleSocial() {
    const currentState = this.state.social;
    this.setState({ social: !currentState });
  };

  handleChange = (e) =>{ 
    this.setState({
      [e.target.name]: e.target.value}
    );
  }

  render() {
    var emailTextStyle = {
      fontWeight: '400'
    };

    return (
      <div className="overflow-hidden">
        <div className="container mb-8">
          <div className="flex flex-wrap items-end lg:w-10/12 lg:-mx-5">
            <div className="w-full lg:w-1/4 lg:px-5">
              <label htmlFor="name" className="block mb-4 lg:mb-0">
                <span className="block pt-2 pb-1 text-sm text-secondary-dark font-display">Name:</span>
                <input type="text" name="name" id="name" className="block w-full p-4 border outline-none appearance-none border-grey focus:border-primary" placeholder="Name" value={this.state.name} onChange={this.handleChange} />
              </label>
            </div>
            <div className="w-full lg:w-1/4 lg:px-5">
              <label htmlFor="title" className="block mb-4 lg:mb-0">
                <span className="block pt-2 pb-1 text-sm text-secondary-dark font-display">Job Title:</span>
                <input type="text" name="title" id="title" className="block w-full p-4 border outline-none appearance-none border-grey focus:border-primary" placeholder="Job Title" value={this.state.title} onChange={this.handleChange} />
              </label>
            </div>
            <div className="w-full lg:w-1/4 lg:px-5">
              <label htmlFor="phone" className="block mb-4 lg:mb-0">
                <span className="block pt-2 pb-1 text-sm text-secondary-dark font-display">Phone Number:</span>
                <input type="tel" name="phone" id="phone" className="block w-full p-4 border outline-none appearance-none border-grey focus:border-primary" placeholder="Phone Number" value={this.state.phone} onChange={this.handleChange} />
              </label>
            </div>
            <div className="w-full lg:w-1/4 lg:px-5">
              <button className="btn btn--smaller btn--outline-grey" onClick={this.toggleSocial}>Toggle Social Links {this.state.social ? `Off` : `On`}</button>
            </div>
          </div>
        </div>

        <div className="container mb-24 border-none outline-none">
          <div className="p-8 border outline-none border-grey md:p-12">
            <div className="max-w-4xl border-none outline-none">
              <div className="pb-3 border-none outline-none">
                <div className="border-none outline-none">
                  <span className="block pb-px mb-0 text-lg antialiased font-bold border-none outline-none font-email">{this.state.name}</span>
                </div>
                <span className="block text-sm antialiased font-light border-none outline-none font-email text-primary" style={emailTextStyle}>{this.state.title}</span>
              </div>
              
              <div className="border-none outline-none mb-15px">
                <span className="inline-block text-sm antialiased font-medium no-underline border-none outline-none font-email text-secondary-dark mr-5px">{this.state.phone}</span>
                <span className="inline-block max-w-2xl mt-px text-sm antialiased font-light border-none outline-none font-email" style={emailTextStyle}><span className="inline-block ml-px antialiased border-none outline-none text-primary mr-5px">•</span> Contact our dedicated service desk advisors directly on: <span className="antialiased no-underline border-none outline-none text-secondary-dark">+1 980 375 6364</span></span>
              </div>

              <hr className="block border-b outline-none border-grey mb-15px"/>

              <div className="border-none outline-none mb-15px">
                <a href="https://breeez.com" className="inline-block mb-3 border-none outline-none mr-5px">
                  <img src="https://adtrak.co.uk/wp-content/themes/adtrak-parent/images/breeez-logo.png" alt="Breeez Logo" className="inline-block mr-5 align-middle border-none outline-none"></img>
                </a>

                <span className={this.state.social ? `antialiased text-xs font-light font-email inline-block align-middle mr-30px outline-none border-none` : `antialiased text-xs font-light font-email inline-block align-middle outline-none border-none` } style={emailTextStyle}>Breeze Inc., <br/>1704 East Blvd, Suite 101, Charlotte, North Carolina 28203</span>

                { this.state.social && (
                  <div className="inline-block border-none outline-none">
                    <a href="https://en-gb.facebook.com/adtrak" className="inline-block align-middle border-none outline-none mr-15px">
                      <img src="https://adtrak.co.uk/wp-content/themes/adtrak-parent/images/facebook.png" alt="Facebook" className="inline-block align-middle border-none outline-none"></img>
                    </a>
                    <a href="https://www.twitter.com/adtrak" className="inline-block align-middle border-none outline-none mr-15px">
                      <img src="https://adtrak.co.uk/wp-content/themes/adtrak-parent/images/twitter.png" alt="Twitter" className="inline-block align-middle border-none outline-none"></img>
                    </a>
                    <a href="https://www.linkedin.com/company/adtrak" className="inline-block align-middle border-none outline-none mr-15px">
                      <img src="https://adtrak.co.uk/wp-content/themes/adtrak-parent/images/linkedin.png" alt="LinkedIn" className="inline-block align-middle border-none outline-none"></img>
                    </a>
                    <a href="https://www.instagram.com/adtrak" className="inline-block align-middle border-none outline-none mr-15px">
                      <img src="https://adtrak.co.uk/wp-content/themes/adtrak-parent/images/insta.png" alt="Instagram" className="inline-block align-middle border-none outline-none"></img>
                    </a>
                  </div>
                )}
              </div>

              {/* <span className="block pb-6 text-xs antialiased font-light border-none outline-none text-grey-dark font-email" style={emailTextStyle}>Adtrak Media Ltd is a limited company registered in England and Wales<br/>Registered number: O3382296. Registered office: 1 Pinnacle Way, Pride Park, Derby, DE24 8ZS</span> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BreeezEmailSignature
