import React from "react"
import BreeezEmailSignature from "../components/breeezEmailSignature/breeezEmailSignature"
import SEO from "../components/seo"

const EmailSigPage = () => {
  return (
    <>
      <SEO noIndex={true} />

      <div className="container mb-8">
        <h2>Breeez Email Signature Generator</h2>
        <p>Fill out your name, job title and phone number below then copy and paste directly into Google Mails signature settings.</p>
      </div>
      <BreeezEmailSignature />
    </>
  )
}

export default EmailSigPage